import {
  notDesktop,
  parseResourceLink,
  styled,
  useInView,
  useIsMobile,
  useQuery,
} from '@obvio/app'
import { randomInt } from '@obvio/utils'
import { motion, useMotionValue, useSpring, useTransform } from 'framer-motion'
import { useRef, useState, useEffect } from 'react'
import { useCallback } from 'react'

import { AnimatedTitle } from './AnimatedTitle'
import { Art } from './Art'
import { useCursorContext } from './Cursor'
import { ScrollDown } from './ScrollDown'

import type { MotionValue } from 'framer-motion'
import type { ReactElement, MouseEvent, RefObject } from 'react'

const PositionedScrollDown = styled(ScrollDown)`
  position: absolute;
  left: ${(theme) => theme.spacing.large};
  bottom: ${(theme) => theme.spacing.large};
`

const ImageWrap = motion(styled.div<{ $size: number; $aspectRatio: number }>`
  height: ${(_, { $size }) => `${$size}vh`};
  position: absolute;
  z-index: 1;
  aspect-ratio: ${(_, { $aspectRatio }) => $aspectRatio};
  > div > div {
    display: none;
  }
  @media ${notDesktop} {
    > div > div {
      display: none;
    }
  }
  span,
  a {
    font-size: 1.25rem;
  }
`)

const Wrap = motion(styled.div`
  width: 100vw;
  height: calc(100vh - var(--nav-height));
  position: relative;
  z-index: 1;
`)

type ImageProps = {
  mouseX: MotionValue<number>
  mouseY: MotionValue<number>
  ratio: number
  xMouseFromCenter: MotionValue<number>
  yMouseFromCenter: MotionValue<number>
  left: number
  top: number
  wrapHeight: number
  wrapWidth: number
  wrapRef: RefObject<HTMLElement>
  children: ReactElement
}

function getCenter(el: HTMLElement, wrapRef: RefObject<HTMLElement>) {
  const rect = el.getBoundingClientRect()
  const parent = wrapRef.current?.getBoundingClientRect()
  if (!parent) {
    return { x: 0, y: 0 }
  }
  return {
    x: rect.left - parent.left + rect.width / 2,
    y: rect.top - parent.top + rect.height / 2,
  }
}

const sizesMap = [
  [59, 110],
  [-4, 60],
  [22, 21],
  [30, -5],
  [8, 75],
  [103, 31],
  [72, 82],
  [35, 65],
  [30, 105],
  [60, -12],
  [88, 94],
  [80, 7],
  [-7, 17],
  [60, 37],
]

function getDistance(a: { x: number; y: number }, b: { x: number; y: number }) {
  return Math.abs(Math.sqrt((a.x - b.x) ** 2 + (a.y - b.y) ** 2))
}

export function Image({
  mouseX,
  mouseY,
  ratio,
  left,
  top,
  xMouseFromCenter,
  yMouseFromCenter,
  wrapHeight,
  wrapWidth,
  wrapRef,
  children,
}: ImageProps): ReactElement {
  const [ready, setReady] = useState(false)
  const ref = useRef<HTMLElement>(null)
  const centerRef = useRef<HTMLDivElement>(null)
  const isMobile = useIsMobile(1024)
  const randomDelay = useRef(randomInt(0, 10))
  const size = useRef(isMobile ? randomInt(7, 10) : randomInt(17, 25))
  const { setValue } = useCursorContext()

  const center = useRef<{ x: number; y: number } | null>(null)

  useEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        center.current = getCenter(ref.current, wrapRef)
      }
      setReady(true)
    }, 3500)
  }, [wrapRef])

  const leftMotionValue = useMotionValue(left)
  const topMotionValue = useMotionValue(top)

  const moveFactor = useTransform(
    leftMotionValue,
    [0, 50, 100],
    [30.5, 15, 30.5],
  )
  const moveFactorTop = useTransform(
    topMotionValue,
    [0, 50, 100],
    [50.5, 25, 50.5],
  )

  const xMoveFlat = useTransform(
    [xMouseFromCenter, moveFactor],
    // @ts-expect-error -- ??
    ([xMouse, factor]) => ((xMouse * factor) / 100) * wrapWidth,
  )

  const yMoveFlat = useTransform(
    [yMouseFromCenter, moveFactorTop],
    // @ts-expect-error -- ??
    ([yMouse, factor]) => ((yMouse * factor) / 100) * wrapHeight,
  )

  const distance = useTransform(
    [mouseX, mouseY, xMoveFlat, yMoveFlat],
    ([mouseXVal, mouseYVal]) => {
      if (
        !ref.current ||
        !ready ||
        isMobile ||
        !center.current ||
        !centerRef.current
      ) {
        return 255
      }

      return getDistance(
        { x: mouseXVal as number, y: mouseYVal as number },
        getCenter(centerRef.current, wrapRef),
      )
    },
  )

  const distanceSizeFactor = useTransform(distance, [255, 75, 0], [1, 10, 10])
  const distanceSizeFactorSpring = useSpring(distanceSizeFactor, {
    stiffness: 350,
    damping: 55,
    bounce: 0,
    mass: 1,
  })

  const l = useTransform(
    distanceSizeFactor,
    (v) => v * ((size.current * ratio) / 2),
  )

  const xMove = useTransform(
    [xMouseFromCenter, moveFactor, l],
    ([xMouse, factor, lV]) =>
      // @ts-expect-error -- ??
      ((ready ? xMouse * factor - lV * 0.5 : 0) / 100) * wrapWidth,
  )

  const yMove = useTransform(
    [yMouseFromCenter, moveFactorTop, l],
    ([yMouse, factor, lV]) =>
      // @ts-expect-error -- ??
      ((ready ? yMouse * factor - lV : 0) / 100) * wrapHeight,
  )

  const xMoveSpring = useSpring(xMove, {
    stiffness: 500,
    damping: 50,
    bounce: 0,
  })

  const yMoveSpring = useSpring(yMove, {
    stiffness: 500,
    damping: 50,
    bounce: 0,
  })

  const transformFlat = useTransform(
    [xMoveFlat, yMoveFlat],
    ([x, y]) => `translateX(${x as number}px) translateY(${y as number}px)`,
  )

  const transform = useTransform(
    [xMoveSpring, yMoveSpring],
    ([x, y]) => `translateX(${x as number}px) translateY(${y as number}px)`,
  )

  const imageHeight = useTransform(
    distanceSizeFactorSpring,
    (v) => `${v * size.current}vh`,
  )

  const zIndex = useTransform(distanceSizeFactorSpring, (d) => d * size.current)

  return (
    <>
      <ImageWrap
        $size={size.current}
        $aspectRatio={ratio}
        ref={ref}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: 0.85 + randomDelay.current / 10,
          duration: 2,
        }}
        style={{
          left: `${left}%`,
          top: `${top}%`,
          height: imageHeight as unknown as string,
          // @ts-expect-error -- This is fine
          transform,
          // @ts-expect-error -- This is fine
          zIndex,
        }}
        onMouseEnter={() => setValue('EXPLORE')}
        onMouseLeave={() => setValue(null)}
      >
        {children}
      </ImageWrap>
      <motion.div
        ref={centerRef}
        style={{
          left: `${left}%`,
          top: `${top}%`,
          transform: transformFlat,
          position: 'absolute',
        }}
      />
    </>
  )
}

type ArtGridProps = {
  art: { id: string }[]
}

export const ART_GRID_SELECT = {
  id: true,
  images: true,
  title: true,
  slug: true,
  year: true,
  merchant: {
    name: true,
  },
} as const

export function ArtGrid({ art }: ArtGridProps): ReactElement {
  const { ref: refInView, inView } = useInView()
  const { setNavTransparent, navTransparent } = useCursorContext()
  const { data } = useQuery('getProducts', {
    args: {
      ids: art.map((a) => parseResourceLink(a.id).id),
      take: 14,
    },
    select: ART_GRID_SELECT,
  })
  const [isClient, setIsClient] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const mouseX = useMotionValue(-1)
  const mouseY = useMotionValue(-1)

  const handleMove = useCallback(
    (e: MouseEvent) => {
      mouseX.set(e.clientX)
      mouseY.set(e.clientY - (ref.current?.getBoundingClientRect().top ?? 0))
    },
    [mouseX, mouseY],
  )

  const xMouseFromCenter = useTransform(
    mouseX,
    [0, ref.current?.clientWidth ?? 0],
    [1, -1],
  )
  const yMouseFromCenter = useTransform(
    mouseY,
    [0, ref.current?.clientHeight ?? 0],
    [1, -1],
  )

  useEffect(() => {
    setIsClient(true)
    mouseX.set(window.innerWidth / 2)
    mouseY.set(window.innerHeight / 2)
  }, [mouseX, mouseY])

  useEffect(() => {
    setNavTransparent(inView)
    return () => {
      if (navTransparent) {
        setNavTransparent(false)
      }
    }
  }, [inView, navTransparent, setNavTransparent])

  return (
    // eslint-disable-next-line jsx-a11y/prefer-tag-over-role -- FIXME???
    <div
      role="presentation"
      style={{ position: 'relative', overflow: 'hidden' }}
      onMouseMove={handleMove}
      ref={refInView}
    >
      <Wrap
        ref={ref}
        initial={{ scale: 0.5 }}
        animate={{ scale: 1 }}
        transition={{
          delay: 1.5,
          duration: 2,
        }}
      >
        {isClient && Array.isArray(data)
          ? data.map((artwork, i) => (
              <Image
                key={artwork.id}
                left={sizesMap[i][0]}
                top={sizesMap[i][1]}
                mouseX={mouseX}
                mouseY={mouseY}
                ratio={artwork.images[0].ratio ?? 1}
                xMouseFromCenter={xMouseFromCenter}
                yMouseFromCenter={yMouseFromCenter}
                wrapHeight={ref.current?.clientHeight ?? 0}
                wrapWidth={ref.current?.clientWidth ?? 0}
                wrapRef={ref}
              >
                <Art artwork={artwork} fullSize />
              </Image>
            ))
          : null}
      </Wrap>
      <AnimatedTitle text="Exquisite Art" />
      <PositionedScrollDown />
    </div>
  )
}
