import { styled } from '@obvio/app'
import { motion } from 'framer-motion'

import type { ReactElement } from 'react'

const Title = motion(styled.div`
  position: absolute;
  z-index: 0;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    display: flex;
  }
`)

const Character = motion(styled.h1`
  ${(theme) => theme.typography.h2?.css}
  font-size: 10.5rem;
`)

const container = {
  show: {},
}

const itemA = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
}

const size = {
  hidden: { scale: 0.5 },
  show: {
    scale: 1,
    transition: {
      staggerChildren: 0.125,
      delayChildren: 1.75,
      duration: 2,
      delay: 1.75,
    },
  },
}

export function AnimatedTitle({ text }: { text: string }): ReactElement {
  return (
    <Title
      aria-label={text}
      role="presentation"
      initial="hidden"
      animate="show"
      variants={container}
    >
      <motion.div variants={size}>
        {[...text].map((character, index) => (
          // eslint-disable-next-line react/no-array-index-key -- this is fine
          <motion.div key={index} variants={itemA}>
            <Character>{character === ' ' ? '\u00A0' : character}</Character>
          </motion.div>
        ))}
      </motion.div>
    </Title>
  )
}
