import {
  AssetsProvider,
  Image,
  Link,
  styled,
  Text,
  useContextPath,
} from '@obvio/app'
import { Stack } from '@obvio/ui'
import { slugify } from '@obvio/utils'

import type { Schema } from '@obvio/app'
import type { DeepPick, AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

const InfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 100%;
  width: 100%;
`

const FullStack = styled(Stack)`
  height: 100%;
`

const FullLink = styled(Link)`
  height: 100%;
`

export type ArtData = DeepPick<
  Schema['Product'],
  {
    id: true
    title: true
    slug: true
    merchant: { id: true; name: true }
    images: true
    year: true
  }
>

type ArtProps = AllowUndefinedForOptional<{
  artwork: ArtData
  className?: string
  fullSize?: boolean
  selectArt?(id: string): void
}>

export function Art({
  artwork,
  selectArt,
  className,
  fullSize,
}: ArtProps): ReactElement {
  const [img] = artwork.images
  const merchantName = artwork.merchant?.name
  const productPath = useContextPath('ecommerce.product')
  const path = `${productPath}/${artwork.slug}`
  return (
    <AssetsProvider path={`/product/${artwork.id}`}>
      <FullStack className={className} kind="vertical" spacing="extraSmall">
        <FullLink href={selectArt ? undefined : path}>
          <Image
            aspectRatio
            onClick={() => selectArt?.(artwork.id)}
            img={img}
            {...(fullSize ? { sizes: '100vw' } : {})}
          />
        </FullLink>
        <InfoWrap>
          <Text tag="span">
            {artwork.title}, {artwork.year}
          </Text>
          {merchantName ? (
            <Link href={`/artist/${slugify(merchantName)}`}>
              by {merchantName}
            </Link>
          ) : null}
        </InfoWrap>
      </FullStack>
    </AssetsProvider>
  )
}
